import React from 'react'
import Image from 'next/image'

import { Column, Section, Rows } from '@/components/layout'
import { Text } from '@/components/common'
import dragDropImg from '@/assets/home/drag-drop-option.png'
import emailImg from '@/assets/home/email-option.png'
import scanImg from '@/assets/home/scan-option.png'
import mobileImg from '@/assets/home/mobile-option.png'

import styles from './HomeUploadOptions.module.scss'

export default function HomeUploadOptions() {
  return (
    <Section bgBrand className={styles.uploadOptions}>
      <Rows>
        <Column center className={styles.header}>
          <Text.H2>Upload & track receipts, bank statements and documents!</Text.H2>
          <Text.P>Scan your document and let our OCR technology handle the rest to save you time by turning them into accurate records in a snap.</Text.P>
        </Column>
        <Column className={styles.options}>
          <div>
            <Image
              alt="Mobile option"
              src={mobileImg}
              style={{ height: 'auto' }}
            />
            <Text.H3>Mobile Phone</Text.H3>
            <Text.P>Quickly snap an image of your receipts or documents with our Neat mobile app.</Text.P>
          </div>
          <div>
            <Image
              alt="Email option"
              src={emailImg}
              style={{ height: 'auto' }}
            />
            <Text.H3>Email</Text.H3>
            <Text.P>Forward your important documents straight from your email.</Text.P>
          </div>
          <div>
            <Image
              alt="Scan option"
              src={scanImg}
              style={{ height: 'auto' }}
            />
            <Text.H3>Scan</Text.H3>
            <Text.P>Use a compatible scanner or Neat's virtual printer and import documents into Neat.</Text.P>
          </div>
          <div>
            <Image
              alt="Drag & drop option"
              src={dragDropImg}
              style={{ height: 'auto' }}
            />
            <Text.H3>Drag & Drop</Text.H3>
            <Text.P>Drag receipts and documents and upload from your desktop into Neat.</Text.P>
          </div>
        </Column>
      </Rows>
    </Section>
  )
}
